import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Container from "components/Container"
import { Link } from "gatsby"
import Button from "components/Button"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import styled from "styled-components"

// Extend ContainerStyle with additional styles
const CustomContainer = styled(Container)`
  text-align: left; // Add your custom styles here

  h1,
  h2,
  p {
    // Target specific elements for styling
  }

  h3 {
    padding: 1rem 2rem;
    margin: 0 auto;
    max-width: 1200px;
    font-size: 20px;
  }

  ul,
  ol {
    padding: 1rem 3rem;
    margin: 0 auto;
    max-width: 1200px;
    font-size: 20px;
    line-height: 24px;
  }
`

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <CustomContainer notitle style={{ padding: "1rem", margin: 0 }}>
        <div style={{}}>
          <h1 style={{ textAlign: "left" }}>{frontmatter.title}</h1>
          <h3 style={{ padding: `0 0 2rem` }}>{frontmatter.date}</h3>
          <h3 style={{ padding: `0 0 2rem` }}>Tags: {frontmatter.tags}</h3>
        </div>

        {/* <Image
          fluid={frontmatter.featuredImage.childImageSharp.fluid}
          style={{
            maxWidth: `500px`,
            width: `100%`,
          }}
        /> */}

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <Link to="/in-depth">
          <Button>Back to Work</Button>
        </Link>
      </CustomContainer>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Template

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
