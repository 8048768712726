import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "typeface-montserrat"

const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid transparent;
  border-radius: 7px;
  color: #162a3e;
  background: linear-gradient(to right, #ffd17a, #fdd894);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  width: 19rem;
  max-width: 100%;
  padding: 13px 0;
  margin: 0 auto;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  outline: none;
  cursor: pointer;

  ${({ secondary }) => secondary && "background-color: #ffd17a;"}
  ${({ secondary }) =>
    secondary && "color: #3C7BBA;"}

  @media only screen and (max-width: 340px) {
    width: 85vw;
  }

  :hover {
    transition: box-shadow 0.1s ease-in-out, transform 0.3s ease-in-out;
    box-shadow: 10px 10px rgba(0, 153, 255, 1),
      /* Blue shadow */ -10px -10px rgba(255, 200, 0, 1); /* Yellow shadow */
    transform: scale(1.02);
  }
`

const Button = ({ secondary, children, ...props }) => {
  return (
    <ButtonContainer {...props} secondary={secondary}>
      {children}
    </ButtonContainer>
  )
}

Button.propTypes = {
  secondary: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
}

Button.defaultProps = {
  secondary: false,
}

export default Button
